'use client';

import React from 'react';
import { Box, Flex, Heading, Text, VisuallyHidden } from '@mezzoforte/forge';
import { BoxProps } from '@chakra-ui/react';
import EntryCard from '@/components/EntryList/EntryCard';
import DummyEntryCard from '@/components/EntryList/DummyEntryCard';
import { ListAnalytics, ListEntry } from '@/types/ListEntry';
import { EntryListContainer } from '@/components/EntryList/EntryListContainer';
import { merge, MergeFn } from '@/util/array';
import { EntryListBanner, PrismicBannerItem } from '@/components/Banners/EntryListBanner';

export interface DummyItem {
  type: 'Dummy';
  key: string;
}

export interface EntryItem {
  type: 'Entry';
  entry: ListEntry;
  key: string;
}

export interface FavoriteEntryItem {
  type: 'FavoriteEntry';
  entry: ListEntry;
  key: string;
}

export interface BannerItem {
  type: 'Banner';
  banner: PrismicBannerItem;
  key: string;
}

export type ListItem = DummyItem | EntryItem | FavoriteEntryItem | BannerItem;
type MapResult = [number, ListItem];

// Merge function inserts a banner (from the left list) if its order number corresponds to the length of
// the result list so far. Otherwise, an auction entry is inserted.
const mergeFunction: MergeFn<MapResult, MapResult> = ([left], _, result) => (result.length + 1 >= left ? -1 : 1);
const Dummy = React.memo(DummyEntryCard);

function renderItem(item: ListItem, analytics: ListAnalytics, index: number) {
  if (item.type === 'Entry') {
    return (
      <EntryCard
        entry={item.entry}
        key={item.key}
        analytics={{
          listId: analytics.listId,
          listName: analytics.listName,
          index,
        }}
      />
    );
  }

  if (item.type === 'FavoriteEntry') {
    return (
      <EntryCard
        isFavoriteEntry
        entry={item.entry}
        key={item.key}
        analytics={{
          listId: analytics.listId,
          listName: analytics.listName,
          index,
        }}
      />
    );
  }

  if (item.type === 'Banner') {
    return (
      <EntryListBanner
        {...item.banner}
        key={item.key}
      />
    );
  }

  return <Dummy key={item.key} />;
}

type EntryListProps = BoxProps & {
  readonly entries?: ListEntry[];
  readonly emptyStateElement?: React.JSX.Element;
  readonly dummyEntryCount: number;
  readonly banners?: PrismicBannerItem[];
  readonly analytics: ListAnalytics;
  readonly isFavoriteList?: boolean;
};

function EmptyState() {
  return (
    <Flex
      w="100%"
      direction="column"
      alignItems="center"
      py={6}
      gap={2}
    >
      <Heading variant="h3">Ei ilmoituksia</Heading>
      <Text>Kokeile yleisempiä hakuehtoja.</Text>
    </Flex>
  );
}

export function EntryList({
  entries,
  emptyStateElement,
  dummyEntryCount,
  banners,
  analytics,
  isFavoriteList = false,
  ...rest
}: EntryListProps) {
  const shouldShowDummies = entries === undefined;

  const sortedBanners = (banners ?? [])
    .sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
    .map<MapResult>((banner) => [banner.order ?? 0, { type: 'Banner', banner, key: `banner-${banner.order}` }]);

  const entriesOrDummies: MapResult[] = shouldShowDummies
    ? Array.from({ length: dummyEntryCount }, (_, index) => [index + 1, { type: 'Dummy', key: `dummy-${index}` }])
    : entries.map((entry, index) => [
        index + 1,
        { type: isFavoriteList ? 'FavoriteEntry' : 'Entry', entry, key: entry.id.toString() },
      ]);

  const items = merge(mergeFunction, sortedBanners, entriesOrDummies);

  if (!shouldShowDummies && items.length === 0) {
    return emptyStateElement ?? <EmptyState />;
  }

  return (
    <Box {...rest}>
      <VisuallyHidden>
        <Heading variant="h2">Kohteet</Heading>
      </VisuallyHidden>
      <EntryListContainer
        data-test="entry-list-container"
        display="grid"
        gridAutoFlow="dense"
        gridTemplateColumns={{ base: '1fr', md: 'repeat(auto-fill, minmax(300px, 1fr))' }}
      >
        {items.map(([index, item]) => renderItem(item, analytics, index))}
      </EntryListContainer>
    </Box>
  );
}
